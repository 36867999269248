<template>
  <div id="app">
    <router-view />
    <notifications group="foo" />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showLoader: false,
    };
  },
  components: {},
};
</script>
<style>
.OT_publisher {
  border-radius: 50% !important;
  border: solid #a99711db !important;
  width: 100px !important;
  height: 100px !important;
  margin-right: 15px;
}
.live-video .OT_publisher {
  border-radius: 0% !important;
  border: solid #a99711db !important;
  width: 100% !important;
  height: 315px !important;
  margin-right: 15px;
}
.live-video .hide_publisher .OT_publisher {
  border-radius: 0% !important;
  border: solid #a99711db !important;
  width: 100% !important;
  height: 315px !important;
  margin-right: 15px;
  display: none !important;
}

/* #publisher.large {
  width: 1280px;
  height: 720px;
} */
.videosession .OT_publisher {
  border-radius: 50% !important;
  border: solid #a99711db !important;
  width: 100px !important;
  height: 100px !important;
  margin-right: 0px;
}
.videosession .OT_subscriber {
  border-radius: 50% !important;
  border: solid #a99711db !important;
  width: 100px !important;
  height: 100px !important;
  margin-right: 0px;
}
.live-video {
  padding: 0 17%;
}
.live-video .OT_publisher .OT_widget-container {
  display: flex !important;
  justify-content: center !important;
}
.live-video .OT_publisher .OT_video-element {
  width: unset !important;
  /* width: 80% !important; */
}
.OT_subscriber {
  overflow: auto !important;
  border-radius: 50% !important;
  border: solid #a99711db !important;
  min-width: 100px !important;
  min-height: 100px !important;
  margin-right: 15px;
  width: 0% !important;
  height: 0% !important;
}
.OT_publisher:nth-child(2),
.OT_publisher:nth-child(3),
.OT_publisher:nth-child(4),
.OT_publisher:nth-child(5) {
  display: none !important;
}
.c-speaker li .call {
  margin-right: 15px;
}
.c-speaker li .call-subscriber .OT_subscriber {
  margin-right: 15px !important;
}
.c-frame-wrapper .OT_publisher:nth-child(2) {
  display: block !important;
}
.vue-loaders {
  position: absolute;
  z-index: 99999;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000001f;
}
</style>
