export const defaultMenuType = "menu-default"; // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const defaultStartPath = "/app/dashboards/default";
export const searchPath = "/app/pages/miscellaneous/search";
export const buyUrl = "https://1.envato.market/nEyZa";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

// export const apiBaseUrl = 'http://192.168.1.212:11121';
// export const liveBaseUrl = 'ws://192.168.1.212:11121';

// export const apiBaseUrl = 'https://test.mournwith.us:4444';
// export const liveBaseUrl = 'wss://test.mournwith.us:8001';

export const apiBaseUrl = "https://api.mournwith.us";
export const liveBaseUrl = "wss://api.mournwith.us";

export const janusUrl = "wss://stun.mournwith.us";
export const iceServer = [{
    urls: "stun:stun.mournwith.us:5349",
  },
  {
    urls: "turn:stun.mournwith.us:5349",
    username: "mwu",
    credential: "12345",
  },
];
export const admin_key = "rick";

export const apiKey = "47433541";
// export const sessionId = '1_MX40NzQzMzc2MX5-MTY0Mjc1MDY5MTg0MH5IUnpWQU5scW1Eb0JyVFY5TEFjTnZBaW1-UH4';
// export const token = 'T1==cGFydG5lcl9pZD00NzQzMzc2MSZzaWc9YjcxZmQ0ZTY2M2Q2NDY2YWYwN2QzNmRjNmE2MTFmNTAzMWM1NTY0MTpzZXNzaW9uX2lkPTFfTVg0ME56UXpNemMyTVg1LU1UWTBNamMxTURZNU1UZzBNSDVJVW5wV1FVNXNjVzFFYjBKeVZGWTVURUZqVG5aQmFXMS1VSDQmY3JlYXRlX3RpbWU9MTY0Mjc1MDY5MyZub25jZT0wLjc4MTE3NjMwODk0ODY1ODMmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY0MjgzNzA5MyZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ=='
// export const janusUrl = 'wss://janus4.bstorm.co.kr:443/wss2';
// export const iceServer = [{"urls":"stun:61.111.3.125:2222"},{"urls":"turn:61.111.3.125:2222","username":"brainst","credential":"worzm%%%"}];
// export const admin_key = 'brain';

export const company_prefix = "4000000";
export const company_wait_prefix = "5000000";